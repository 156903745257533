import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Dialog,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Switch,
  Table,
  type TableEmptyState,
  type TableSortAddon,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useDialog,
  useEvent,
  useForm,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  is,
  parseCurrency,
  parseStringCopy,
  sortBy,
} from "@adaptive/design-system/utils";
import {
  patchAccountBalance,
  patchPlaidAccountOwner,
} from "@api/bank-accounts";
import {
  type BillListItemResponse,
  useGetBillBalanceByCustomerQuery,
} from "@api/bills";
import { handleErrors } from "@api/handle-errors";
import type { CombinedPaymentMethods } from "@bills/types";
import { useGetLienWaiverTemplatesQuery } from "@lien-waiver/api";
import {
  LIEN_WAIVER_NOT_REQUIRED_STATUS,
  LIEN_WAIVER_STATUS,
} from "@lien-waiver/constants";
import type { MissingFields } from "@lien-waiver/types";
import { BillPaymentConfirmationDialog } from "@src/bills/components/pay-bill-step/payment-bill-step-confirmation-dialog";
import { PaymentSuccessMessage } from "@src/bills/components/pay-bill-step/payment-success-message";
import {
  type BillPaymentConfirmation,
  type BillPaymentConfirmationPayload,
} from "@src/bills/components/pay-bill-step/types";
import {
  AccountSelectDialog,
  type Step as StepSelectAccountDialog,
} from "@src/settings/components/account-select-dialog";
import { useAppSelector } from "@store/hooks";
import { useTwoFactorAuth } from "@store/ui";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";
import { useVendorAction } from "@vendors/hooks";
import { vendorSelector } from "@vendors/store";
import type { Stage } from "@vendors/types";

import { VendorCreditSelectionDialog } from "../../components";
import { COMBINED_PAYMENTS_STRINGS, PAYMENT_METHOD } from "../../constants";
import {
  useAppliedVendorCreditsForMultiplePayments,
  useAutoApplyVendorCreditsToMultiplePayments,
  useCreatePaymentV2Mutation,
  usePaymentOptionsInfo,
  useVendorCreditsByVendor,
} from "../../hooks";
import { multiplePaymentFormSchema } from "../../schemas";
import {
  type BankAccountOption,
  type DataBankAccount,
  type DynamicBalanceVendorCredit,
  type MultiplePaymentForm,
} from "../../types";
import { parseBillListToFormPaymentList } from "../../utils";
import { removeBillFromGroupedList } from "../../utils";
import { getMissingFieldsInfo } from "../../utils/get-missing-fields-info";

import {
  CombinedPaymentDialogContext,
  type CombinedPaymentDialogContextType,
} from "./context";
import { MissingLienWaiverFieldsInfo } from "./missing-lien-waiver-fields-info";
import { getColumns } from "./multiple-payments-table-columns";

type CombinedPaymentDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  selectedRows: BillListItemResponse[];
  onRemoveBill: (billId: string) => void;
  onClose: (paymentsFinished?: boolean) => void;
  paymentMethod: CombinedPaymentMethods;
};

const INITIAL_VALUES_MULTIPLE_PAYMENT_FORM = {
  combinedPayments: true,
  payWhenLienWaiverIsSigned: false,
  payments: [],
};

export const CombinedPaymentDialog = ({
  dialog: paymentDialog,
  selectedRows,
  onRemoveBill,
  onClose,
  paymentMethod,
}: CombinedPaymentDialogProps) => {
  const [sort, setSort] = useState("");
  const { checkTwoFactorAuth } = useTwoFactorAuth();
  const [triggerCreatePayment, { isLoading: isLoadingMarkingAsPaid }] =
    useCreatePaymentV2Mutation();
  const showPaymentConfirmationDialog = useDialog({
    lazy: true,
  });

  const totalAmountToPay = useMemo(() => {
    return sumBy(selectedRows, "balance");
  }, [selectedRows]);

  const accountSelectDialog = useMultiStepDialog({
    initialStep: "set-account" as StepSelectAccountDialog,
  });
  const isAchPaymentMethod = paymentMethod === PAYMENT_METHOD.ACH;

  const [selectedAccountBalance, setSelectedAccountBalance] = useState<
    BankAccountOption | undefined
  >();

  const { data: lienWaiverTemplatesData } = useGetLienWaiverTemplatesQuery({
    withStatus: true,
  });

  const defaultTemplate = useMemo(() => {
    return lienWaiverTemplatesData?.find((template) => template.isDefault);
  }, [lienWaiverTemplatesData]);

  const { canManageLienWaivers } = useClientSettings();
  const navigate = useNavigate();

  const [paymentsPayload, setPaymentsPayload] =
    useState<BillPaymentConfirmationPayload | null>(null);

  const billsInfo = useMemo(() => {
    return {
      billIds: selectedRows.map((row) => row.id),
      vendorIds: selectedRows.map((row) => row.vendor?.id || ""),
    };
  }, [selectedRows]);

  const {
    data: paymentOptions,
    isLoading: paymentOptionsLoading,
    refetch: refetchPaymentOptions,
  } = usePaymentOptionsInfo({
    billIds: billsInfo.billIds,
    vendorIds: billsInfo.vendorIds,
    skip: !billsInfo.billIds.length,
  });

  const { data: billListBalanceByCustomer } = useGetBillBalanceByCustomerQuery(
    {
      billIds: billsInfo.billIds,
    },
    {
      skip: !billsInfo.billIds.length,
    }
  );

  const paymentOptionsByVendor = useDeepMemo(() => {
    if (!paymentOptions.length) return;

    return paymentOptions.reduce(
      (acc, option) => {
        acc[option.vendor.id] = {
          ...option,
          accounts: option.accounts.filter(
            (account) =>
              (isAchPaymentMethod && account.achOption) ||
              account.markAsPaidOption
          ),
        };

        return acc;
      },
      {} as Record<string, DataBankAccount>
    );
  }, [paymentOptions, isAchPaymentMethod]);

  const markAsPaidOptionsByVendor = useDeepMemo(() => {
    if (!paymentOptions.length) return;

    return paymentOptions.reduce(
      (acc, option) => {
        acc[option.vendor.id] = {
          ...option,
          accounts: option.accounts.filter(
            (account) => account.markAsPaidOption
          ),
        };

        return acc;
      },
      {} as Record<string, DataBankAccount>
    );
  }, [paymentOptions]);

  const curriedMarkAsPaid = useCallback(
    async (payments: BillPaymentConfirmation[]) => {
      await checkTwoFactorAuth(async () => {
        try {
          const payload = payments.map((payment) => ({
            bills: payment.bills.map((bill) => bill.url),
            options: {
              appliedAmount: payment.amountToPay,
              customerBankAccount:
                payment.bankAccountOption.customerBankAccount?.url,
              customerCard: payment.bankAccountOption.customerCard?.url,
              customerPaymentAccount:
                payment.bankAccountOption.markAsPaidOption
                  ?.customerPaymentAccount?.url,
              vendorBankAccount:
                payment.bankAccountOption.markAsPaidOption?.vendorBankAccount
                  ?.url,
              paymentMethod: PAYMENT_METHOD.MARK_AS_PAID,

              processPrerequisites:
                payment.bankAccountOption.markAsPaidOption
                  ?.processPrerequisites,
              appliedProcessOn: payment.currentDebitDate,
            },
            lienWaivers: payment.lienWaivers.map((lienWaiver) => ({
              ...lienWaiver,
              lienWaiverTemplateId:
                lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver?.lienWaiverTemplate
                  ? `${parseRefinementIdFromUrl(
                      lienWaiver?.lienWaiverTemplate
                    )}`
                  : null,
              status:
                !lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver.status !== LIEN_WAIVER_STATUS.NOT_REQUIRED
                  ? LIEN_WAIVER_STATUS.NOT_SELECTED
                  : lienWaiver.status,
            })),
          }));
          const createdPayments = await triggerCreatePayment(payload).unwrap();
          toast.success(
            <PaymentSuccessMessage
              paymentsLength={createdPayments.length}
              totalAmountToPay={totalAmountToPay}
              paymentMethod={paymentMethod}
              onViewPayment={() => {
                navigate("/bill-payments");
              }}
            />
          );

          analytics.track("billBulkPay", {
            billIds: payments.flatMap((payment) =>
              payment.bills.map((bill) => bill.id)
            ),
          });
          paymentDialog.hide();
          onClose(true);
        } catch (e) {
          handleErrors(e);
        }
      });
    },
    [
      checkTwoFactorAuth,
      navigate,
      onClose,
      paymentDialog,
      paymentMethod,
      totalAmountToPay,
      triggerCreatePayment,
    ]
  );

  const {
    register: registerFormControl,
    values: formValues,
    errors: formErrors,
    setValue: setMultiplePaymentFormValue,
    ...form
  } = useForm({
    schema: multiplePaymentFormSchema,
    initialValues: INITIAL_VALUES_MULTIPLE_PAYMENT_FORM,
    onSubmit: async (values: MultiplePaymentForm) => {
      const payments: BillPaymentConfirmation[] = values.payments.map(
        (payment) => {
          const appliedVendorCreditsTotalAmount = sumBy(
            payment.appliedVendorCredits || [],
            "appliedAmount"
          );

          const amountToPay =
            sum(payment.balance || "0", appliedVendorCreditsTotalAmount) || 0;

          const shouldMarkAsPaid =
            amountToPay === 0 || paymentMethod === PAYMENT_METHOD.MARK_AS_PAID;

          const selectedAccountBalance = shouldMarkAsPaid
            ? markAsPaidOptionsByVendor?.[
                payment.vendor?.id || ""
              ]?.accounts.find(
                (account) => account.value === payment.customerBankAccount
              )
            : paymentOptionsByVendor?.[payment.vendor?.id || ""]?.accounts.find(
                (account) => account.value === payment.customerBankAccount
              );

          const debitDate =
            payment.debitDate ||
            selectedAccountBalance?.achOption?.paymentSchedules?.[0]
              ?.processOn ||
            new Date();

          const paymentConfirmation: BillPaymentConfirmation = {
            billUrls: payment.bills?.map((bill) => bill.url) || [],
            bills: payment.bills || [],
            amountToPay,
            paymentMethod: shouldMarkAsPaid
              ? PAYMENT_METHOD.MARK_AS_PAID
              : PAYMENT_METHOD.ACH,
            balance: parseCurrency(payment.balance || "0") || 0,
            bankAccountOption: selectedAccountBalance as BankAccountOption,
            payWhenLienWaiverIsSigned: shouldMarkAsPaid
              ? false
              : values.payWhenLienWaiverIsSigned,
            vendor: {
              id: payment.vendor.id,
              email: payment.vendorEmail,
              hasBankingAch:
                !!selectedAccountBalance?.achOption?.vendorBankAccount,
              displayName: payment.vendor.displayName,
            },
            currentDebitDate: debitDate
              ? formatDate(debitDate, "iso-8601-now")
              : undefined,
            lienWaivers:
              payment.lienWaivers?.map((lienWaiver) => ({
                ...lienWaiver,
                status:
                  !lienWaiver?.lienWaiverTemplate && !lienWaiver?.status
                    ? LIEN_WAIVER_STATUS.NOT_SELECTED
                    : lienWaiver?.status,
                sendLienWaiverOnPayment: true,
                lienWaiverTemplateIsValid: true,
              })) || [],
            appliedVendorCredits: payment.appliedVendorCredits || [],
            appliedVendorCreditsTotalAmount: sumBy(
              payment.appliedVendorCredits || [],
              "appliedAmount"
            ),
            vendorCreditsRemainingAmount:
              vendorCreditBalanceByVendor[payment.vendor.id],
          };

          return paymentConfirmation;
        }
      );

      setPaymentsPayload({ payments });
      if (isAchPaymentMethod) {
        showPaymentConfirmationDialog.show();
        analytics.track("billPaymentShowConfirmationDialog", {
          isMultiplePayments: true,
          isCombinedPayments: values.combinedPayments,
          isPayWhenLienWaiverSigned: values.payWhenLienWaiverIsSigned,
          numberOfPayments: values.payments?.length,
          hasVendorCredits: values.payments?.some(
            (payment) => (payment?.appliedVendorCredits?.length || 0) > 0
          ),
        });
      } else {
        await curriedMarkAsPaid(payments);
      }
    },
  });

  const hasAvailableVendorCredits = useMemo(() => {
    return paymentOptions.some((option) =>
      option.vendorCredits?.some((vc) => vc.openBalance)
    );
  }, [paymentOptions]);

  const { vendorCreditsByVendor, vendorCreditBalanceByVendor } =
    useVendorCreditsByVendor(formValues, paymentOptions);

  const onChangeVendorCredits = useCallback(
    ({
      appliedVendorCredits,
      paymentIndex,
    }: {
      appliedVendorCredits: DynamicBalanceVendorCredit[];
      paymentIndex: number;
    }) => {
      setMultiplePaymentFormValue(
        `payments.${paymentIndex}.appliedVendorCredits`,
        appliedVendorCredits.map((credit) => ({
          id: credit.id,
          url: credit.url,
          appliedAmount: credit.appliedAmount || 0,
        })) || [],
        { shouldValidate: true }
      );
      if (formValues.combinedPayments) {
        setMultiplePaymentFormValue(
          `payments.${paymentIndex}.data`,
          formValues.payments?.[paymentIndex]?.data?.map((data) => ({
            ...data,
            appliedVendorCredits:
              appliedVendorCredits.map((credit) => ({
                id: credit.id,
                url: credit.url,
                appliedAmount: credit.appliedAmount || 0,
              })) || [],
          })),
          { shouldValidate: true }
        );
      }
    },
    [
      formValues.combinedPayments,
      formValues.payments,
      setMultiplePaymentFormValue,
    ]
  );

  useAutoApplyVendorCreditsToMultiplePayments({
    vendorCreditsByVendor,
    payments: formValues.payments,
    hasAvailableVendorCredits,
    onAutoApplyVendorCreditsToPayment: onChangeVendorCredits,
    combinedPayments: formValues.combinedPayments,
  });

  const valuesRef = useRef(formValues);

  const validationMessage = useMemo(() => {
    const checkIfPaymentHasErrorBasedOnKey = (
      key: keyof MultiplePaymentForm["payments"][number]
    ) =>
      is.array(formErrors?.payments) &&
      formErrors?.payments?.some((error) => error && !!error[key]);

    const missingBankAccount =
      checkIfPaymentHasErrorBasedOnKey("customerBankAccount") ||
      (typeof formErrors?.payments === "string" && !!formErrors?.payments);

    if (missingBankAccount) {
      return COMBINED_PAYMENTS_STRINGS.ERROR_PAYMENT_BANK_ACCOUNT_MISSING;
    }

    const missingCustomerPaymentAccount = checkIfPaymentHasErrorBasedOnKey(
      "customerPaymentAccount"
    );
    if (missingCustomerPaymentAccount) {
      return COMBINED_PAYMENTS_STRINGS.ERROR_QUICKBOOKS_ACCOUNT_MISSING;
    }

    const missingDebitDate = checkIfPaymentHasErrorBasedOnKey("debitDate");

    if (missingDebitDate) {
      return COMBINED_PAYMENTS_STRINGS.DEBIT_DATE;
    }

    if (formErrors?.payWhenLienWaiverIsSigned) {
      return formErrors?.payWhenLienWaiverIsSigned;
    }
    const missingLienWaiverInfo =
      is.array(formErrors?.payments) &&
      formErrors?.payments?.some(
        (error) =>
          error &&
          is.string(error.lienWaivers) &&
          error.lienWaivers ===
            COMBINED_PAYMENTS_STRINGS.LIEN_WAIVER_MISSING_INFO
      );

    if (missingLienWaiverInfo) {
      return COMBINED_PAYMENTS_STRINGS.LIEN_WAIVER_MISSING_INFO;
    }
  }, [formErrors]);

  const customers = useMemo(() => {
    return selectedRows.flatMap((row) => row.customers);
  }, [selectedRows]);

  const lienWaiverMissingFields = useMemo(() => {
    const paymentsWithInvalidLienWaivers = formValues.payments.filter(
      (payment) =>
        payment.lienWaivers &&
        payment.lienWaivers.some(
          (lienWaiver) => !lienWaiver?.missingFields?.isValid
        )
    );

    if (!paymentsWithInvalidLienWaivers.length) return;

    return getMissingFieldsInfo({
      paymentsWithInvalidLienWaivers,
      selectedRows,
      customers,
    });
  }, [formValues, customers, selectedRows]);

  const { banking, info: vendor } = useAppSelector(vendorSelector);

  const onDrawerClose = useEvent(() => {
    setMultiplePaymentFormValue(
      "payments",
      formValues?.payments.map((payment) => {
        if (payment.vendor.id === vendor.id.toString()) {
          const paymentOption = paymentOptionsByVendor?.[payment.vendor.id];
          const selectedAccount = paymentOption?.accounts.find(
            (account) => account.value === payment.customerBankAccount
          );
          const nextAvailablePaymentSchedule =
            selectedAccount?.achOption?.paymentSchedules?.[0];

          return {
            ...payment,
            vendorBankAccount: banking.url,
            debitDate: nextAvailablePaymentSchedule?.processOn,
          };
        }
        return payment;
      })
    );
  });

  const { showVendorById } = useVendorAction({ onDrawerClose });

  const curriedOpenVendor = useCallback(
    (vendorId: string, stage: Stage) => () => {
      showVendorById(vendorId, stage);
    },
    [showVendorById]
  );

  const emptyState = useMemo<TableEmptyState>(
    () => ({
      title: COMBINED_PAYMENTS_STRINGS.NO_BILLS_SELECTED,
      subtitle: COMBINED_PAYMENTS_STRINGS.NO_BILLS_SELECTED_SUBTITLE,
    }),
    []
  );

  const onChangeLienWaiverTemplate = useEvent(
    async (
      value: string,
      extra?: {
        customer?: string;
        index?: number;
        missingFields?: MissingFields;
      }
    ) => {
      const isStatus = LIEN_WAIVER_NOT_REQUIRED_STATUS.includes(value);
      const payment = formValues.payments[extra?.index || 0];
      const combinedPayments = formValues.combinedPayments;

      const result = {
        status: !value
          ? LIEN_WAIVER_STATUS.NOT_SELECTED
          : isStatus
            ? value
            : undefined,
        lienWaiverTemplate: isStatus ? null : value,
        missingFields: extra?.missingFields,
      };

      if (combinedPayments) {
        const lienWaiverRowIndex = payment.lienWaivers?.findIndex(
          (lienWaiver) => lienWaiver.customer === extra?.customer
        );

        if (lienWaiverRowIndex === -1) return;
        const lienWaiverRow = payment.lienWaivers?.[lienWaiverRowIndex!];
        setMultiplePaymentFormValue(
          `payments.${extra?.index}.lienWaivers.${lienWaiverRowIndex}`,
          {
            ...lienWaiverRow,
            ...result,
          },
          {
            shouldValidate: true,
          }
        );
      } else {
        setMultiplePaymentFormValue(
          `payments.${extra?.index}.lienWaivers`,
          payment.lienWaivers?.map((lienWaiver) => {
            return { ...lienWaiver, ...result };
          }),
          { shouldValidate: true }
        );
      }
    }
  );

  const isLoading = paymentOptionsLoading || !formValues.payments.length;

  const headerAddon = useMemo(
    () => ({
      sticky: {
        offset: -64,
      },
      hide: isLoading,
    }),
    [isLoading]
  );

  const footerAddon = useMemo(
    () => ({
      hide: isLoading,
      sticky: { offset: -64 },
    }),
    [isLoading]
  );

  const sortAddon = useMemo<TableSortAddon>(
    () => ({
      value: sort,
      onChange: setSort,
    }),
    [sort]
  );

  const onChangePayFrom = useCallback(
    async (value: string, vendorId: string, index: number) => {
      const paymentOption = paymentOptionsByVendor?.[vendorId];
      const selectedAccount = paymentOption?.accounts.find(
        (account) => account.value === value
      );
      const nextAvailablePaymentSchedule = isAchPaymentMethod
        ? selectedAccount?.achOption?.paymentSchedules?.[0]
        : null;

      const option = isAchPaymentMethod
        ? selectedAccount?.achOption
        : selectedAccount?.markAsPaidOption;

      setMultiplePaymentFormValue(
        `payments.${index}`,
        {
          ...formValues?.payments[index],
          customerBankAccount: value,
          vendorBankAccount: option?.vendorBankAccount?.url,
          customerPaymentAccount: option?.customerPaymentAccount?.url,
          debitDate: !formValues.payWhenLienWaiverIsSigned
            ? nextAvailablePaymentSchedule?.processOn || null
            : null,
          nextAvailableDebitDate:
            (!formValues.combinedPayments &&
              nextAvailablePaymentSchedule?.processOn) ||
            undefined,
        },
        { shouldValidate: true }
      );
    },
    [
      formValues,
      paymentOptionsByVendor,
      setMultiplePaymentFormValue,
      isAchPaymentMethod,
    ]
  );

  const shouldResetFormRef = useRef(true);

  const onToggleCombinedPayments = useCallback(
    async (value: boolean) => {
      setMultiplePaymentFormValue("combinedPayments", value);
      shouldResetFormRef.current = true;
    },
    [setMultiplePaymentFormValue]
  );

  const onTogglePayWhenLienWaiverIsSigned = useCallback(
    async (value: boolean) => {
      setMultiplePaymentFormValue("payWhenLienWaiverIsSigned", value);
      setMultiplePaymentFormValue(
        "payments",
        formValues?.payments.map((payment) => {
          const paymentOption = paymentOptionsByVendor?.[payment.vendor.id];
          const selectedAccount = paymentOption?.accounts.find(
            (account) => account.value === payment.customerBankAccount
          );
          const nextAvailablePaymentSchedule =
            selectedAccount?.achOption?.paymentSchedules?.[0];

          const isMarkAsPaid =
            sum(
              sumBy(payment.appliedVendorCredits || [], "appliedAmount"),
              parseCurrency(payment.balance || "0") || 0
            ) <= 0;

          return {
            ...payment,
            debitDate:
              value && !isMarkAsPaid
                ? null
                : payment.debitDate || nextAvailablePaymentSchedule?.processOn,
          };
        }),
        { shouldValidate: true }
      );
    },
    [formValues, paymentOptionsByVendor, setMultiplePaymentFormValue]
  );

  const onSetPaymentAccount = useEvent((account: BankAccountOption) => {
    setSelectedAccountBalance(account);
    accountSelectDialog.show();
  });

  const {
    vendorCreditSelectionDialog,
    editVendorCreditState,
    onEditVendorCredits,
    onSaveVendorCredits,
  } = useAppliedVendorCreditsForMultiplePayments({
    formValues,
    vendorCreditsByVendor,
    onChangeVendorCredits,
  });

  const columns = useMemo(() => {
    return getColumns({
      combined: formValues.combinedPayments,
      hasAvailableVendorCredits,
      canManageLienWaivers,
    });
  }, [
    formValues.combinedPayments,
    hasAvailableVendorCredits,
    canManageLienWaivers,
  ]);

  const enhancedOnRemoveBill = useCallback(
    (billId: string) => {
      if (!formValues.combinedPayments) {
        setMultiplePaymentFormValue(
          "payments",
          formValues.payments.filter((payment) => payment.billId !== billId)
        );
      } else {
        setMultiplePaymentFormValue(
          "payments",
          removeBillFromGroupedList(formValues.payments, billId)
        );
      }
      onRemoveBill(billId);
    },
    [onRemoveBill, formValues, setMultiplePaymentFormValue]
  );

  const context = useDeepMemo<CombinedPaymentDialogContextType>(
    () => ({
      combinedPayments: formValues.combinedPayments,
      registerFormControl,
      paymentOptionsByVendor,
      paymentOptionsLoading,
      enhancedOnRemoveBill,
      curriedOpenVendor,
      onChangeLienWaiverTemplate,
      onChangePayFrom,
      onSetPaymentAccount,
      formValues,
      onEditVendorCredits,
      vendorCreditBalanceByVendor,
      paymentMethod,
    }),
    [
      registerFormControl,
      paymentOptionsByVendor,
      paymentOptionsLoading,
      enhancedOnRemoveBill,
      curriedOpenVendor,
      onChangeLienWaiverTemplate,
      onSetPaymentAccount,
      onChangePayFrom,
      formValues,
      onEditVendorCredits,
      vendorCreditBalanceByVendor,
      paymentMethod,
    ]
  );

  const onCloseConfirmationDialog = useEvent((paymentsFinished?: boolean) => {
    showPaymentConfirmationDialog.hide();
    if (paymentsFinished) {
      paymentDialog.hide();
      onClose(paymentsFinished);
    }
  });

  const onPutObject = useEvent(async (values: any) => {
    try {
      await (selectedAccountBalance?.accountOwner
        ? patchPlaidAccountOwner({
            plaidAccountOwner: selectedAccountBalance?.accountOwner,
            ...values,
          })
        : patchAccountBalance({
            account: selectedAccountBalance?.customerBankAccount,
            ...values,
          }));
      refetchPaymentOptions();
    } catch (e) {
      handleErrors(e);
    }
  });

  const data = useMemo(() => {
    if (sort === "vendor") {
      return sortBy(
        formValues.payments,
        "displayName",
        sort.startsWith("-") ? "desc" : "asc"
      );
    }

    return formValues.payments;
  }, [formValues.payments, sort]);

  const onClosePaymentDialog = useEvent(() => {
    const handler = () => {
      paymentDialog.hide();
      onClose();
    };
    dialog.confirmation({
      title: "You have unsaved changes",
      message:
        "Are you sure your want to leave without completing this payment? You will lose any changes you made.",
      action: {
        primary: { children: "Stay on payment" },
        secondary: { children: "Leave without saving", onClick: handler },
      },
    });
  });

  useEffect(() => {
    if (!selectedRows.length && paymentDialog.isVisible) {
      onClose();
      toast.info(COMBINED_PAYMENTS_STRINGS.NO_BILLS_SELECTED);
    }
  }, [onClose, selectedRows, paymentDialog.isVisible]);

  useEffect(() => {
    if (!paymentOptionsByVendor || !shouldResetFormRef.current) return;

    const defaultAccountValues = Object.entries(paymentOptionsByVendor).reduce(
      (acc, [key, paymentOption]) => {
        let defaultPaymentOption = paymentOption.accounts.find((account) =>
          isAchPaymentMethod
            ? account.achOption?.default
            : account.markAsPaidOption?.default
        );

        if (!defaultPaymentOption) {
          const options = paymentOption.accounts.filter((account) =>
            isAchPaymentMethod
              ? !!account.achOption
              : !!account.markAsPaidOption
          );

          if (options.length === 1) {
            defaultPaymentOption = options[0];
          }
        }
        const detailedPaymentOption = isAchPaymentMethod
          ? defaultPaymentOption?.achOption
          : defaultPaymentOption?.markAsPaidOption;

        const customerPaymentAccount =
          detailedPaymentOption?.customerPaymentAccount;

        let vendorBankAccount = detailedPaymentOption?.vendorBankAccount;

        if (!vendorBankAccount) {
          vendorBankAccount = isAchPaymentMethod
            ? paymentOption.accounts[0].achOption?.vendorBankAccount
            : paymentOption.accounts[0].markAsPaidOption?.vendorBankAccount;
        }

        acc[key] = {
          customerBankAccount: defaultPaymentOption?.value,
          customerPaymentAccount: customerPaymentAccount?.url,
          vendorBankAccount: vendorBankAccount?.url,
        };
        return acc;
      },
      {} as Record<
        string,
        {
          customerBankAccount?: string;
          vendorBankAccount?: string;
          customerPaymentAccount?: string;
        }
      >
    );

    const shouldUsePreviousPayments =
      valuesRef.current?.combinedPayments !== formValues.combinedPayments;

    setMultiplePaymentFormValue(
      `payments`,
      parseBillListToFormPaymentList(
        selectedRows,
        formValues.combinedPayments,
        billListBalanceByCustomer || [],
        defaultTemplate?.url
      ).map((row, index) => {
        const vendorId = row.vendor?.id?.toString() || "";

        const previousPayment = shouldUsePreviousPayments
          ? valuesRef.current?.payments[index]
          : defaultAccountValues[vendorId];

        const currentPaymentOption = paymentOptionsByVendor[
          vendorId
        ].accounts.find(
          (account) => account.value === previousPayment?.customerBankAccount
        );

        const customerPaymentAccount =
          currentPaymentOption?.achOption?.customerPaymentAccount?.url;

        const nextAvailablePaymentSchedule =
          currentPaymentOption?.achOption?.paymentSchedules?.[0];

        return {
          ...row,
          index,
          paymentMethod,
          customerPaymentAccount,
          customerBankAccount: previousPayment?.customerBankAccount,
          vendorBankAccount: previousPayment?.vendorBankAccount,
          debitDate: nextAvailablePaymentSchedule?.processOn,
          nextAvailableDebitDate: nextAvailablePaymentSchedule?.processOn,
        };
      }),
      {
        shouldValidate: true,
      }
    );
    shouldResetFormRef.current = false;
  }, [
    paymentOptionsByVendor,
    paymentMethod,
    formValues.combinedPayments,
    selectedRows,
    setMultiplePaymentFormValue,
    billListBalanceByCustomer,
    defaultTemplate?.url,
    isAchPaymentMethod,
  ]);

  useLayoutEffect(() => {
    valuesRef.current = formValues;
  }, [formValues]);

  return (
    <>
      {isLoadingMarkingAsPaid && <Loader position="fixed" />}
      <Dialog
        size="auto"
        show={paymentDialog.isVisible}
        variant="dialog"
        onClose={() => onClosePaymentDialog()}
      >
        <DialogHeader>
          {parseStringCopy(COMBINED_PAYMENTS_STRINGS.HEADER_TITLE, {
            quantity: formValues.payments.length,
            suffix: formValues.payments.length > 1 ? "s" : "",
            amount: formatCurrency(totalAmountToPay, { currencySign: true }),
          })}
        </DialogHeader>
        <DialogContent>
          <Flex
            width="full"
            direction="column"
            minWidth="800px"
            as="form"
            gap="md"
            {...form.props}
          >
            <Flex gap="lg">
              <Switch
                label={COMBINED_PAYMENTS_STRINGS.COMBINED_PAYMENTS_TITLE}
                checked={formValues.combinedPayments}
                onChange={onToggleCombinedPayments}
                data-testid="combined-payment"
              />
              {canManageLienWaivers && (
                <Switch
                  label={COMBINED_PAYMENTS_STRINGS.PAY_WHEN_LIEN_WAIVER_SIGNED}
                  checked={formValues.payWhenLienWaiverIsSigned}
                  onChange={onTogglePayWhenLienWaiverIsSigned}
                  disabled={paymentMethod === PAYMENT_METHOD.MARK_AS_PAID}
                  hintMessage={
                    !canManageLienWaivers
                      ? COMBINED_PAYMENTS_STRINGS.LIEN_WAIVER_NOT_ENABLED
                      : undefined
                  }
                  data-testid="lien-waiver"
                />
              )}
            </Flex>
            <CombinedPaymentDialogContext.Provider value={context}>
              <Table
                data={data}
                loading={isLoading}
                columns={columns}
                size="sm"
                sort={sortAddon}
                header={headerAddon}
                footer={footerAddon}
                emptyState={emptyState}
                data-testid="combined-payments-table"
              />
              {lienWaiverMissingFields && (
                <Alert
                  variant={{
                    icon: "exclamation-circle",
                    iconColor: "warning-200",
                    background: "warning-100",
                  }}
                >
                  <AlertTitle>
                    {COMBINED_PAYMENTS_STRINGS.REQUIRED_LIEN_WAIVER_DATA_TITLE}
                  </AlertTitle>
                  <AlertContent as={Flex} direction="column" gap="lg">
                    <Text>
                      {COMBINED_PAYMENTS_STRINGS.FILL_OUT_LW_INFO_TITLE}
                    </Text>
                    <Flex direction="column" gap="md">
                      {Object.entries(lienWaiverMissingFields).map(
                        ([key, missingField]) => (
                          <MissingLienWaiverFieldsInfo
                            key={key}
                            frontendUrl={missingField.frontendUrl!}
                            objectId={missingField.id!}
                            displayName={missingField.displayName!}
                            missingFields={missingField.missingFields}
                          />
                        )
                      )}
                    </Flex>
                  </AlertContent>
                </Alert>
              )}
            </CombinedPaymentDialogContext.Provider>
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Flex width="full">
            <Button
              variant="ghost"
              color="neutral"
              block
              onClick={() => onClosePaymentDialog()}
            >
              {COMBINED_PAYMENTS_STRINGS.CANCEL}
            </Button>
          </Flex>
          <Tooltip as={Flex} width="full" message={validationMessage}>
            <Button
              type="submit"
              form={form.id}
              block
              disabled={
                !selectedRows.length || form.isSubmitting || !form.isValid
              }
              data-testid="pay-button"
            >
              {paymentMethod === PAYMENT_METHOD.MARK_AS_PAID
                ? `${COMBINED_PAYMENTS_STRINGS.MARK_AS_PAID} ${formatCurrency(
                    totalAmountToPay,
                    { currencySign: true }
                  )}`
                : COMBINED_PAYMENTS_STRINGS.REVIEW_AND_PAY}
            </Button>
          </Tooltip>
        </DialogFooter>
      </Dialog>
      {showPaymentConfirmationDialog.isRendered && paymentsPayload && (
        <BillPaymentConfirmationDialog
          onClose={onCloseConfirmationDialog}
          paymentsInfo={paymentsPayload}
          dialog={showPaymentConfirmationDialog}
        />
      )}
      {accountSelectDialog.isRendered && (
        <AccountSelectDialog
          dialog={accountSelectDialog}
          selectHeader={COMBINED_PAYMENTS_STRINGS.LINK_ACCOUNT_TITLE}
          selectSubHeader={`Bank account: ${selectedAccountBalance?.label}`}
          selectLabel={COMBINED_PAYMENTS_STRINGS.LINK_ACCOUNT_TITLE}
          putObject={onPutObject}
          isBankAccount={true}
        />
      )}
      {vendorCreditSelectionDialog.isRendered && (
        <VendorCreditSelectionDialog
          {...editVendorCreditState}
          dialog={vendorCreditSelectionDialog}
          onSave={onSaveVendorCredits}
        />
      )}
    </>
  );
};
