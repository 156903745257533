import { type RenderLienWaiverTemplatePDFResponse } from "../api/types";
import {
  BILL_FIELD_LABELS,
  JOB_FIELD_LABELS,
  VENDOR_FIELD_LABELS,
} from "../constants";
import { type MissingFields } from "../types";

export const formatMissingFields = (missingFields?: string[]) => {
  if (!missingFields) return;
  return {
    customer: {
      missingFields: missingFields.filter((field) =>
        JOB_FIELD_LABELS.includes(field)
      ),
    },
    vendor: {
      missingFields: missingFields.filter((field) =>
        VENDOR_FIELD_LABELS.includes(field)
      ),
    },
    bill: {
      missingFields: missingFields.filter((field) =>
        BILL_FIELD_LABELS.includes(field)
      ),
    },
  };
};

type FormatMissingFieldsPayload = {
  data?: RenderLienWaiverTemplatePDFResponse;
  customer: string;
  vendor: string;
  previousMissingFields: MissingFields;
};

export const formatMissingFieldsToPayload = ({
  data,
  customer,
  vendor,
  previousMissingFields,
}: FormatMissingFieldsPayload) => {
  const formattedMissingFields = formatMissingFields(
    data?.missingFields || undefined
  );

  if (formattedMissingFields?.customer.missingFields.length) {
    previousMissingFields.customers = previousMissingFields.customers || [];
    previousMissingFields.customers?.push({
      ...formattedMissingFields.customer,
      url: customer,
    });
    previousMissingFields.isValid = false;
  }
  if (
    formattedMissingFields?.bill?.missingFields.length &&
    !previousMissingFields.bill
  ) {
    previousMissingFields.bill = {
      ...formattedMissingFields.bill,
    };
    previousMissingFields.isValid = false;
  }

  if (
    formattedMissingFields?.vendor?.missingFields.length &&
    !previousMissingFields.vendor
  ) {
    previousMissingFields.vendor = {
      ...formattedMissingFields.vendor,
      url: vendor,
    };
    previousMissingFields.isValid = false;
  }

  return previousMissingFields;
};
