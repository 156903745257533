import { type BillListItemResponse } from "@api/bills/types";
import { type MissingLienWaiverFieldsInfo } from "@lien-waiver/types";

import { type MultiplePaymentForm } from "../types";

type GetMissingFieldsInfoProps = {
  paymentsWithInvalidLienWaivers: MultiplePaymentForm["payments"];
  selectedRows: BillListItemResponse[];
  customers: BillListItemResponse["customers"];
};

export const getMissingFieldsInfo = ({
  paymentsWithInvalidLienWaivers,
  selectedRows,
  customers,
}: GetMissingFieldsInfoProps) => {
  const missingFieldsInfo: Record<string, MissingLienWaiverFieldsInfo> = {};

  paymentsWithInvalidLienWaivers.forEach((payment) => {
    payment.lienWaivers?.forEach((lienWaiver) => {
      const fields = lienWaiver.missingFields;

      fields?.customers?.forEach((customer) => {
        const customerInfo = customers.find(
          (item) => item.url === customer.url
        );
        if (customerInfo?.url) {
          missingFieldsInfo[customerInfo.url] = {
            ...customerInfo,
            missingFields: customer.missingFields,
            frontendUrl: `/jobs/${customerInfo.id}`,
            id: customerInfo.id,
            objectType: "customer",
          };
        }
      });

      if (fields?.vendor) {
        const vendorInfo = selectedRows.find(
          (row) => row.vendor?.url === fields.vendor?.url
        )?.vendor;

        if (vendorInfo?.url) {
          missingFieldsInfo[vendorInfo.url] = {
            ...vendorInfo,
            missingFields: fields.vendor.missingFields,
            frontendUrl: `/vendors/${vendorInfo.id}`,
            id: vendorInfo.id,
            objectType: "vendor",
          };
        }
      }

      if (fields?.bill) {
        const billsWithInvalidLienWaivers = selectedRows.filter(
          (row) => !row.dueDate && row.id === payment.billId
        );

        billsWithInvalidLienWaivers.forEach((bill) => {
          missingFieldsInfo[bill.url!] = {
            displayName: `#${bill.docNumber}`,
            missingFields: fields.bill?.missingFields || [],
            frontendUrl: `/bills/${bill.id}`,
            id: bill.id,
            objectType: "bill",
            url: bill.url,
          };
        });
      }
    });
  });

  return missingFieldsInfo;
};
