import React, { type ReactNode, useEffect, useRef } from "react";
import { useSearchParams } from "react-router";
import { Flex, Text } from "@adaptive/design-system";
import type {
  LienWaiverCustomer,
  OnChangeHandler,
  OnLoadTemplateDataHandler,
  RequestOnPayment,
} from "@lien-waiver/types";

import type { LienWaiver, LienWaiverVendor } from "../api/types";
import {
  LIEN_WAIVER_NOT_REQUESTED_STATUS,
  STRINGS,
} from "../constants/constants";

import { BillLienWaiverField } from "./bill-lien-waiver-field";
export type BillLienWaiverFieldProps = {
  lienWaiverCustomers?: LienWaiverCustomer[];
  onChange?: OnChangeHandler;
  onLoadTemplateData?: OnLoadTemplateDataHandler;
  requestOnPayment?: RequestOnPayment;
  recipientEmail?: string;
  vendor: LienWaiverVendor;
  paymentAmount?: number;
  billBalance?: number;
  netPaymentAmount?: number;
  billId?: string | number;
  billPaymentId?: string;
  billLienWaiver?: LienWaiver | null;
  onRequestUpdate?: () => void;
  billAmountPerCustomer?: Record<string, number>;
  billInternalVersion?: string;
  label?: ReactNode;
};

export const BillLienWaivers = ({
  onChange,
  recipientEmail,
  vendor,
  paymentAmount,
  netPaymentAmount,
  billId,
  billPaymentId,
  requestOnPayment,
  onRequestUpdate,
  billLienWaiver,
  billInternalVersion,
  onLoadTemplateData,
  label,
  lienWaiverCustomers,
  billAmountPerCustomer,
  billBalance,
}: BillLienWaiverFieldProps) => {
  const [params] = useSearchParams();
  const lienWaiverId = params.get("lien-waiver-id");
  const lienWaiverFieldRef = useRef<HTMLDivElement>(null);

  const shouldScroll = !!lienWaiverId && lienWaiverId === billLienWaiver?.id;

  useEffect(() => {
    if (shouldScroll) {
      lienWaiverFieldRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [shouldScroll]);

  return (
    <Flex width="full" direction="column" ref={lienWaiverFieldRef} gap="xl">
      {label || (
        <Text size="xl" weight="bold">{`${STRINGS.LIEN_WAIVER}${
          (billLienWaiver ? 1 : 0) + (lienWaiverCustomers?.length || 0) > 1
            ? "s"
            : ""
        }`}</Text>
      )}
      {billLienWaiver &&
        !LIEN_WAIVER_NOT_REQUESTED_STATUS.some(
          (status) => billLienWaiver.status === status
        ) && (
          <BillLienWaiverField
            onChange={onChange}
            recipientEmail={recipientEmail}
            vendor={vendor}
            paymentAmount={paymentAmount}
            billId={billId}
            billPaymentId={billPaymentId}
            onRequestUpdate={onRequestUpdate}
            requestOnPayment={{
              checked: true,
              ...requestOnPayment,
            }}
            billInternalVersion={billInternalVersion}
            billLienWaiver={billLienWaiver}
          />
        )}
      {lienWaiverCustomers?.map((lienWaiverCustomer, index) => {
        const billPercentageForCustomer =
          billBalance && billAmountPerCustomer?.[lienWaiverCustomer.customer]
            ? billAmountPerCustomer?.[lienWaiverCustomer.customer] / billBalance
            : undefined;
        const lienWaiverPaymentAmount =
          paymentAmount && billPercentageForCustomer
            ? Math.round(paymentAmount * billPercentageForCustomer * 100) / 100
            : paymentAmount;
        const lienWaiverNetPaymentAmount =
          netPaymentAmount && billPercentageForCustomer
            ? Math.round(netPaymentAmount * billPercentageForCustomer * 100) /
              100
            : netPaymentAmount;
        return (
          <BillLienWaiverField
            key={index}
            customer={lienWaiverCustomer.customer}
            value={
              lienWaiverCustomer.lienWaiverTemplate || lienWaiverCustomer.status
            }
            onChange={onChange}
            recipientEmail={recipientEmail}
            vendor={vendor}
            paymentAmount={lienWaiverPaymentAmount}
            netPaymentAmount={lienWaiverNetPaymentAmount}
            onLoadTemplateData={onLoadTemplateData}
            billId={billId}
            billPaymentId={billPaymentId}
            onRequestUpdate={onRequestUpdate}
            billInternalVersion={billInternalVersion}
            requestOnPayment={{
              checked: lienWaiverCustomer.sendLienWaiverOnPayment,
              ...requestOnPayment,
            }}
          />
        );
      })}
    </Flex>
  );
};
